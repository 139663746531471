import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useDispatch, useSelector } from 'react-redux';
import { FINGERPRINT_LOGIN_METHOD } from '../gqlStrings/mutations/auth';
import {
  setAuthToken,
  setFingerPrintToken,
  setNotUser,
  setRemember,
  setAuthTokens,
  setSuspect,
  setUser
} from '../redux/features/auth/authSlice';
import { startQueue } from '../redux/features/bag/bagSlice';
import { RootState } from '../redux/store';

export default function FingerPrintLazy() {
  const fingerPrint = useSelector(
    (state: RootState) => state.authEvent.fingerPrint
  );
  const notUser = useSelector((state: RootState) => state.authEvent.notUser);
  const isLogin = useSelector((state: RootState) => state.authEvent.isLogin);
  const selectedRegion = useSelector(
    (state: RootState) => state.regionEvent.selectedRegion
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const { Crawler } = require('es6-crawler-detect');
    var CrawlerDetector = new Crawler();
    if (!CrawlerDetector.isCrawler(navigator.userAgent)) {
      createFingerPrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fingePrintLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fingerPrint]);

  function makeString(length: any) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function createFingerPrint() {
    if (!fingerPrint) {
      if (process.env.NEXT_PUBLIC_ENV === 'production') {
        const fpPromise = FingerprintJS.load();
        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            dispatch(setFingerPrintToken(result.visitorId));
          })
          .catch(() => {
            dispatch(setFingerPrintToken(makeString(12)));
          });
      } else {
        dispatch(setFingerPrintToken(makeString(12)));
      }
    }
  }

  const [mutateFingerPrintLogin] = useMutation(FINGERPRINT_LOGIN_METHOD, {
    context: { clientName: 'laravel' },
    onCompleted: (getUserData) => {
      dispatch(setUser(getUserData.fingerPrintLogin.user));
      dispatch(setAuthTokens(getUserData.fingerPrintLogin.authTokens));
      dispatch(
        setAuthToken(
          getUserData.fingerPrintLogin.authTokens.filter(
            (token: any) => token.region === selectedRegion.key
          )[0].token
        )
      );
      dispatch(setSuspect(true));
      dispatch(setRemember(true));
      dispatch(startQueue());
    },
    onError: (error) => {
      if (error.message == 'not_found_fingerprint') {
        dispatch(setNotUser(true));
      }
    }
  });

  function fingePrintLogin() {
    if (!isLogin && fingerPrint && !notUser) {
      mutateFingerPrintLogin({
        variables: {
          input: {
            fingerprint: fingerPrint || ''
          }
        }
      });
    }
  }

  return <></>;
}
